import React, { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import Loading from './components/Loading/Loading.jsx';
import { Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop.js';
import PaymentSuccess from './pages/Payments/PaymentSuccess.js';
import PaymentFail from './pages/Payments/PaymentFail.js';

const Login = lazy(() => import('./pages/Login/Login.jsx'));
const Signup = lazy(() => import('./pages/Login/Signup.jsx'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard.jsx'));
const Privacy = lazy(() => import('./pages/privacy/privacy.jsx'));
const Eula = lazy(() => import('./pages/Eula/eula.jsx'));
const Tos = lazy(() => import('./pages/TermsOfService/tos.jsx'));
const Notfound = lazy(() => import('./pages/notfound.jsx'));
const SignoutQBO = lazy(() => import('./pages/signoutQBO/signoutQBO.jsx'));
const Callback = lazy(() => import('./pages/Login/Callback.jsx'));
const StartOAuth = lazy(() => import('./pages/Login/StartOAuth.jsx'));
const Disconnect = lazy(() => import('./pages/disconnect/disconnect.jsx'));

function App() {
  // Root Class Should Only Be Used To Route
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/tos" component={Tos} />
            <Route exact path="/eula" component={Eula} />
            <Route exact path="/signoutQBO" component={SignoutQBO} />
            <Route exact path="/callback" component={Callback} />
            <Route exact path="/startoauth" component={StartOAuth} />
            <Route exact path="/disconnect" component={Disconnect} />
            <Route exact path="/payment_success" component={PaymentSuccess} />
            <Route exact path="/payment_fail" component={PaymentFail} />

            <Route exact component={Notfound} />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
