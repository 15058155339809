import React from 'react';

import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';

import Container from '@material-ui/core/Container/Container.js';

const PaymentSuccess = () => {
  function navigateToOnboarding() {
    window.location.href = 'https://www.geniussheets.com/onboarding';
  }
  return (
    <>
      <Header />
      <main
        style={{
          padding: '15vh 0 20vh 0',
          minHeight: '100vh',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="md">
          <img src="imgs/payment_success.svg" style={{ maxWidth: '450px' }} />
          <h2>Your payment is Successful</h2>
          <p>
            Thank you for your payment. An automated payment receipt will be
            sent to your registered email.
          </p>
          <p>Check out our <a onClick={navigateToOnboarding}>onboarding page</a> for more comprehensive instructions.</p>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default PaymentSuccess;
